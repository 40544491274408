import React from 'react'
import Search from '../components/search/search'
import { graphql } from 'gatsby'

export default ({ data, ...props }) => {
  const properties = data.allProperty.edges.map((e) => e.node)
  return (
    <Search
      {...props}
      data={data}
      properties={properties}
      negotiation={'let'}
      internalTypes={['unit', 'standalone']}
    />
  )
}

export const query = graphql`
  query {
    allProperty(
      filter: {
        negotiation: { eq: "let" }
        internal_type: { ne: "project" }
        is_preview: { ne: true }
      }
    ) {
      edges {
        node {
          ...PropertyCard
        }
      }
    }
  }
`
